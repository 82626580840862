*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
  background-position: 50% 50%;
  box-sizing: border-box;
  font: inherit;
  color: inherit;
}

body {
  position: relative;
  color: $white;
  font-family: HKGrotesk, Helvetica, Arial, sans-serif;
  font-size: 16px;
  overflow-x: hidden;
  height: 100vh;
  background-color: #171133 !important;
}

.color-black {
  color: $black
}

.layout {

  &-main {
    min-height: 50vh;
    background-color: $main-bg;
  }
}

a {
  color: $primary;
  font-weight: 500;

  &:hover {
    color: $primaryButtonHover;
    text-decoration: underline;
  }

  &:focus,
  &:active {
    color: $primaryButtonActive;
    text-decoration: underline;
  }
}

.ant-btn {
  height: auto;
  font-weight: 400;
  letter-spacing: 1px;
  background-color: #FF3180;
  border-color: #FF3180;
  color: white;
}
input {
  border-radius: 20px !important;
}

.ant-dropdown-menu-item-disabled {
  color: rgba(0, 0, 0, 0.25) !important;
}

.ant-select-dropdown {
  border-radius: 10px;

  .ant-select-item {
    font-size: 15px;
  }
}

.ant-picker-panel-container {
  border-radius: 10px;
}

.ant-tabs-tab {
  font-size: 17px;
  line-height: 20px;
  color: $text-gray-light;
  font-weight: 800;

  &:hover {
    color: $blueHover;
  }

  &.ant-tabs-tab-active {

    .ant-tabs-tab-btn {
      font-size: 17px;
      line-height: 20px;
      color: $blue;
      font-weight: 800;
    }
  }
}

.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
  color: $blueActive;
}

.ant-tabs-ink-bar {
  background: $blue;
}

.qu-radio {

  &-panel {
    display: flex;
  }

  .qu-radio-button {
    flex: 1;
    text-align: center;
  }

  &-row {
    display: flex;
    width: 100%;
  }

  &-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-accept {
    flex: 1;
    margin-right: 10px;

    &.ant-radio-button-wrapper.ant-radio-button-wrapper-disabled:not(.ant-radio-button-wrapper-checked) {
      border-color: $border-default;
      color: $border-default;

      svg {
        color: $border-default;

        path {
          fill: $border-default;
        }
      }

      &:hover {
        color: $border-default;
      }
    }

    svg {
      min-width: 14px;
      margin-right: 8px;
    }

    &.ant-radio-button-wrapper {
      border-radius: 10px;
      border-width: 2px;
      height: 38px;
      border-style: solid;
      font-size: 17px;
      font-weight: 600;
      line-height: 20px;

      &:not(:first-child)::before {
        display: none;
      }

      &:hover {
        color: $green-alt;
      }
    }

    &.ant-radio-button-wrapper-checked {
      color: $green-alt;
      border-color: $green-alt !important;
      background-color: rgba(130, 255, 160, 0.1);
    }
  }

  &-reject {
    flex: 1;

    &.ant-radio-button-wrapper.ant-radio-button-wrapper-disabled:not(.ant-radio-button-wrapper-checked) {
      border-color: $border-default;
      color: $border-default;

      svg {
        color: $border-default;
      }

      &:hover {
        color: $border-default;
      }
    }

    svg {
      min-width: 20px;
      max-width: 20px;
      color: $labelRejected;
      margin-right: 5px;
    }

    &.ant-radio-button-wrapper {
      border-radius: 10px;
      border-width: 2px;
      height: 38px;
      border-style: solid;
      font-size: 17px;
      font-weight: 600;
      line-height: 20px;

      &:not(:first-child)::before {
        display: none;
      }

      &:hover {
        color: $labelRejected;
      }
    }

    &.ant-radio-button-wrapper-checked {
      color: $labelRejected;
      border-color: $labelRejected !important;
      background-color: rgba(239, 71, 31, 0.05);
    }
  }
}

.qu-page-loader {
  display: flex;
  align-items: center;

  .ant-progress {

    &-outer {
      display: flex;
    }

    &-inner {
      border-radius: 0;
    }

    &-bg {
      border-radius: 0;
      height: 4px !important;
    }
  }
}

// Status labels

$labelColors: ('rejected': $labelRejected,
        'review': $primary,
        'unpaid': $primary,
        'pending': $labelPending,
        'approved': $labelApproved,
        'accepted': $labelApproved,
        'running': $labelRunning,
        'completed': $labelCompleted,
        'paid': $labelCompleted,
        'po_issued': $labelApproved);

@each $class,
$color in $labelColors {
  .status-label.#{$class} {
    background-color: $color;
  }
}

.status-label {
  min-width: 93px;
  font-weight: 700;
  font-size: 11px;
  letter-spacing: 0.8px;
  line-height: 1;
  background-color: $mid-gray;
  color: $white;
  text-transform: uppercase;
  user-select: none;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  padding: 6px;
  border-radius: 12px;
}

.ant-form-item {

  &.mb-0 {
    margin-bottom: 0;
  }
}

/**/
/* distributing popover */
/**/
.qu-distributing-popover {
  padding-left: 0;

  &::after,
  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-content {
    margin-top: -20px;
  }

  .ant-popover-inner {
    box-shadow: none;
    background-color: transparent;

    &-content {
      padding: 0;
    }
  }
}

.distributing-popover {
  position: relative;
  width: 340px;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: #FFFFFF;
  box-shadow: 0 16px 24px 0 rgba(37, 62, 69, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
}

.distributing-popover::after {
  content: '';
  position: absolute;
  top: 24px;
  left: -8px;
  width: 14px;
  height: 14px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-right-width: 0;
  border-bottom-width: 0;
  border-top-left-radius: 2px;
  background: #FFFFFF;
  transform: rotate(-45deg);
}

.distributing-popover__item {
  margin-top: 16px;
}

.distributing-popover__heading {
  font-weight: 500;
}

.distributing-popover__item:first-child {
  margin-top: 0;
}

.distributing-popover__description {
  margin-top: 2px;
  line-height: 1.25;
}

.distributing-popover--alt {
  background: #E9F4FB;
  box-shadow: none;
}

.distributing-popover--alt::after {
  background: #E9F4FB;
}

/**/
/* banner popover */
/**/
.qu-banner-popover {
  pointer-events: none;

  .ant-popover-inner {
    width: 340px;
    padding: 23px 47px;
    border-radius: 10px;
    background: $white;
    text-align: center;

    &-content {
      padding: 0;
    }
  }

  &.popover-padded {

    .ant-popover-inner {
      padding: 24px;
    }
  }
}

.banner-popover__image {
  margin-right: -24px;
  margin-left: -24px;
}


.data-view {
  font-size: 14px;
  line-height: 17px;

  &-label {
    color: $gray-icon;
    margin-bottom: 2px;
  }

  &-value {
    font-weight: 500;
  }
}

.qu-statistic {

  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 28px;
  }

  &-list {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &-item {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 40px;

    &:last-child {
      margin-right: 0;
    }
  }

  &-value {
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
  }

  &-name {
    color: $text-gray-light;
    font-size: 13px;
    line-height: 16px;
  }

  @media only screen and (max-width: 666px) {

    &-wrapper {
      margin-bottom: 20px;

      h1 {
        display: none;
      }
    }

    &-list {
      justify-content: space-between;
      width: 100%;
    }

    &-item {
      align-items: flex-start;
      margin-right: 0;
    }

    &-value {
      font-size: 16px;
      line-height: 19px;
    }

    &-name {
      font-size: 12px;
      line-height: 15px;
    }
  }
}

// Text colors
$text-colors: (black: $black,
        white: $white,
        gray: $gray,
        red: $error,
        primary: $primary,
        green: $green,
        lightgreen: $light-green,
        blue: $blue,
);

.text {

  @each $status,
  $color in $text-colors {

    &-#{$status} {
      color: $color;
    }
  }
}

.qu-modal {

  .ant-modal {

    &-header {
      border-radius: 10px 10px 0 0;
    }

    &-content {
      border-radius: 10px;
    }

    &-close {
      top: -50px;
      right: -50px;
      color: $white;

      &-x {
        line-height: 68px;
      }
    }
  }

  .qu-preview {

    &-head {
      display: flex;
      justify-content: space-between;
    }

    &-body {
      display: flex;
      justify-content: center;
    }
  }

  @media only screen and (max-width: 666px) {

    .ant-modal {

      &-content {
        border-radius: 10px;
        overflow: hidden;
      }

      &-close {
        top: 0;
        right: 0;
        color: $gray-icon;
      }
    }

    .qu-preview {

      &-head {
        margin-right: 30px;
        flex-direction: column;

        h3 {
          font-size: 16px;
        }

        span {
          font-size: 12px;
        }
      }
    }
  }
}

.qu-collapse {

  &-row {
    padding: 20px 16px 0;
    border-top: 1px solid $border-alt;

    &:nth-child(odd) {
      background-color: $main-bg;
    }
  }
}

.ProfileCard__description-text {
  @include multiLineEllipsis($lineHeight: 1.2em, $lineCount: 2, $bgColor: white);
  word-break: break-all;
}

.cell-pricing-blur {
  filter: blur(5px);
}

.autocomplete-dropdown {
  position: absolute;
  z-index: 2;
  margin-top: 12px;
  margin-bottom: 12px;
  background-color: $white;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .04);

  .suggestion-item {
    padding: 5px 12px;
    cursor: pointer;
    font-size: 15px;
    transition: background-color .2s;

    &.active {
      background-color: #f5f5f5;
    }

    &:first-child {
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
    }

    &:last-child {
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
    }
  }
}

.icon-link {
  position: relative;
  display: inline-flex;
  align-items: center;
  color: $light-blue;
  text-decoration: underline;
  box-shadow: none;
  cursor: pointer;

  svg {
    margin-right: 5px;
  }

  .hidden {
    position: absolute;
  }

  &:hover {
    text-decoration: none;
  }

  &.disabled,
  &.ant-tooltip-disabled-compatible-wrapper,
  & button:disabled {
    display: inline-flex;
    padding: 0;
    border: none;
    pointer-events: none;
    color: $mid-gray;
    background-color: transparent;
    text-decoration: none;
  }

  &-upload {
    display: inline-flex;

    .ant-upload {
      display: flex;
      align-items: center;
      color: $success-green;
    }
  }

  &-cancel {
    height: 14px;
    width: 14px;
    line-height: 0;
    margin-left: 4px;
    cursor: pointer;

    svg {
      height: 14px;
      width: 14px;
      fill: $gray
    }

    &:hover {
      svg {
        fill: $black;
      }
    }
  }
}

.ant-form-item {
  &.mb-0 {
    margin-bottom: 0;
  }
}

.progress_container {
  .ant-progress {
    position: fixed;
    z-index: 1;
  }
}

.status-title {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  margin-top: 38px;

  h1 {
    margin-right: 24px;
    line-height: 33px;
  }
}

.qu-header-right {
  position: relative;
}

.refer {
  &.only-desktop {
    position: absolute;
    left: -140px;
  }
}

.ant-dropdown-menu-item {
  .discount-item {
    display: flex;
    align-items: center;

    svg {
      height: 16px;
      width: 16px;
      margin-right: 10px;
    }
  }
}

.qu-metrics-card {
  .ant-card-body {
    padding: 0;
  }

  .ant-card-grid {
    padding: 0;
  }
}

.currency_dropdown {
  width: 100px;
}

.cursorPointer {
  cursor: pointer;
}

.qu-header {
  background-color: $primary !important;
}
.qu-button-transparent {
  background-color: transparent !important;
  color: white !important;
}
.qu-button-transparent.active {
  background-color: $light-green !important;
  color: black !important;
}
.qu-button-transparent:hover {
  background-color: $light-green !important;
}

.qu-header .qu-button.active {
  background-color: $light-green !important;
  color: black !important;
}
.qu-header .qu-button:hover,
.qu-header .qu-button.active {
  background-color: $light-green !important;
  color: black !important;
}
.qu-button {
  background-color: $light-green !important;
  color: black !important;
}
.qu-button:hover {
  background-color: $primary !important;
  color: white !important;
}
.notifications-menu-popover .ant-badge-count {
  background-color: $light-green !important;
  box-shadow: 0 0 0 1px $light-green !important;
  color: black !important;
}
@media only screen and (max-width: 666px) {
  .status-title {
    flex-direction: column;
    margin-top: 0;
    margin-bottom: 30px;

    h1 {
      text-align: center;
      margin-right: 0;
      margin-bottom: 12px;
    }
  }
}

.ant-notification {
  z-index: 1050 !important;
}

.product-sub-type {
  font-size: 14px;
}

.reCaptchaHeight iframe {
  height: unset !important;
}

.html-arabic {
  direction: rtl !important;
  font-family: 'Bahij TheSansArabic Plain';

  .notifications-menu-popover .ant-badge-count {
    direction: ltr;
  }

  .auth-section-footer {
    .user-menu-icon {
      margin-right: inherit;
      margin-left: 10px;
    }
  }

  .refer.only-desktop {
    right: -140px;
    left: inherit;

    .refer-button svg {
      margin-left: 8px;
      margin-right: 0;
    }
  }

  &.layout.auth {
    background-position: -40vw center;
  }

  .q-table_menu.ant-dropdown-trigger {
    right: inherit;
    left: 10px;
  }

  .ant-select-arrow {
    right: inherit;
    left: 11px;
  }

  .BSUnit__edit {
    right: inherit;
    left: 0;
  }

  .BSUnit__list .i {
    margin-right: 0px;
    margin-left: 7px;
  }

  .ant-input-prefix {
    margin-right: 0;
    margin-left: 4px;
  }

  .q-table-list .check-icon {
    margin: 0 0px 0 5px;
  }

  .q-table-product_title {
    text-align: start;
  }

  .q-table-product_thumb {
    margin-right: 0px;
    margin-left: 10px;
  }

  .q-table .ant-table-tbody > tr > td {
    text-align: start;
  }

  .ant-table-thead > tr > th {
    text-align: start;
  }

  .ant-dropdown-menu {
    direction: rtl;
    text-align: start;

    .user-menu-icon {
      margin-top: -1px;
      margin-left: 10px;
    }
  }

  .qu-header-logo {
    svg {
      width: 100px;
    }
  }

  .user-menu {
    margin-left: 0;
    margin-right: 18px;

    .user-name {
      margin-right: 0;
      margin-left: 10px;
    }
  }

  .qu-brief-footer {
    .footer-center .qu-button:not(:last-child) {
      margin-right: 0;
      margin-left: 10px;
    }

    .footer-right .qu-button {
      right: inherit;
      left: 0;
    }
  }

  .qu-brief-header .header-stepper {
    margin-left: 0;
    margin-right: -174px;
  }

  .qu-brand-label {
    .qu-brand-logo {
      margin-right: 0;
      margin-left: 16px;
    }
  }

  .cart-summary__edit {
    float: left;
  }

  .cart-summary__dlist dt {
    float: right;
  }

  .ProfileCard__logo {
    margin-right: 0;
    margin-left: 14px;
  }

  .ProfileCard__services-item:not(:last-child) {
    margin-right: 0;
    margin-left: 10px;
  }

  .ProfileCard__services-item .i {
    margin-right: 0;
    margin-left: 9px;
  }

  @media (min-width: 666px) and (max-width: 1366px) {
    .ProfileCard__services-item .i {
      margin-right: 0;
      margin-left: 4px;
    }
  }

  .ProfileCard__tags {
    margin-right: auto;
    margin-left: inherit;
  }

  .client-preview-container-header .qu-button-wrapper {
    margin-left: inherit;
    margin-right: auto;
  }

  .client-preview-container-percentage {
    margin-left: inherit;
    margin-right: 15px;
  }

  .qu-brief-main {
    margin-right: 0;
    margin-left: 16px;
  }

  .SSUnit {
    .SSUnit__heading {
      padding-right: 0;
      padding-left: 40px;
    }

    .SSUnit__edit {
      left: 0;
      right: inherit;
    }

    .SSUnit__thumb {
      margin-right: 0;
      margin-left: 10px;
    }
  }

  .layout.profile {
    .client-preview-container-body .info-row-key {
      margin-right: 0;
      margin-left: 30px;
    }

    .PartnerProfileSidebar div.current .index {
      transform: translate(120%, 0);
    }

    .PartnerProfileSidebar div.finished div.index,
    .PartnerProfileSidebar div.unfinished .index {
      transform: translate(120%, 0);
      top: 20%;
    }
  }

  .qu-details-aside {
    .button__counter {
      margin: 0 4px 0 -4px;
    }
  }

  .partner-layout {
    .ant-table-tbody {
      th,
      td {
        text-align: start;
      }
    }
  }
}

.html-arabic.user-menu-list {
  direction: rtl;
  text-align: start;

  .user-menu-icon {
    margin-top: -1px;
    margin-left: 10px;
  }
}

.d-none {
  display: none !important;
}

.greyscale {
  filter: grayscale(100%);
}
.color-red {
  color: red;
}
.color-green {
  color: green;
}

.partner-api {
  &.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 30px;
    padding-bottom: 40px;
  }
  .sections {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    gap: 40px;
    margin-top: 10px;

    .left-section {
      flex: 1;
    }
    .right-section {
      flex: 1;
    }
  }
}

@media only screen and (max-width: 666px) {
  .partner-api {
    &.container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      border-bottom: 1px solid #e5e5e5;
      margin-bottom: 30px;
      padding-bottom: 40px;
    }
    .sections {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
      margin-top: 10px;

      .left-section {
        flex: 1;
        width: 100%;
      }
      .right-section {
        flex: 1;
        width: 100%;
      }
    }
  }
}
.ant-radio-group-solid .qu-radio-button.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: $light-green !important;
  border-color: $green-alt !important;
  color: black !important;
}
.ant-radio-group-solid .qu-radio-button:hover {
  color: $green-alt !important;
}
.ant-radio-group-solid .qu-radio-button.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  background: $green-alt !important;
  border-color: $green-alt !important;
}
.ant-radio-group-solid
  .qu-radio-button.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background: $green-alt !important;
}
.ant-radio-group-solid
  .qu-radio-button.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
  background-color: $green-alt !important;
}
.ant-form-item .ant-form-item-label >label {
  color: white;
}
.footer {
  background-color: #FF3180;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
}
:where(.css-dev-only-do-not-override-1m62vyb).ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: #FF3180;
  border-color: #FF3180;
  background-color: #ffffff;
}
.bg-grey {
  background-color: #8B8899;
}
.ant-upload-list-item-container {
  border-radius: 5px;
  padding: 10px;
}
.ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-list-item-actions .ant-upload-list-item-action {
  opacity: 1 !important;
}
.tags-input {
  .ant-select-selector {
    background-color: #8B8899 !important;
    height: 50px;
    border-color: #8B8899 !important;
  }
  .ant-select-selection-placeholder {
    color: rgb(166,165,169);
  }
}
.tracks-table {
  .ant-table-row {
    background-color: #2F2948 !important;
    margin-bottom: 20px;
    border-radius: 5px;
  }
  .ant-table-tbody >tr >td {
    border-bottom: 10px solid #171133 !important;
    min-height: 50px !important;
    color: white;
  }

  .ant-table-tbody >tr >td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .ant-table-tbody >tr >td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .ant-table-tbody >tr >td.ant-table-cell-row-hover {
    background: #171133 !important;
  }
  .ant-table-wrapper .ant-table-tbody >tr >td.ant-table-cell-row-hover {
    background: #171133 !important;
  }
  .ant-table-content{
    background: #171133 !important;
  }
}
.track-list {
  .ant-input-group-wrapper {
    background-color: #8B8899;
    border-radius: 5px;
  }
  .ant-btn {
    background-color: unset;
    border-color: transparent;
  }
  .ant-input-search-button:not(.ant-btn-primary) {
    color: white !important;
  }
  .ant-input {
    background-color: unset;
    border-color: transparent;
    color: white;
  }
  .ant-input-search .ant-input:focus {
    border-color: transparent;
  }
  .ant-select-selector {
    background-color: #8B8899 !important;
    border-color: #8B8899 !important;
    color: white;
  }
}
.upload-container {
  .ant-upload-list-item-container{
    background-color: #2F2948 !important;
    margin-top: 20px;
  }
  .ant-upload-list .ant-upload-list-item {
    margin-top: 0;
  }
}
.logout {
  background-color: unset;
  border-color: transparent;
  color: black;
}
.home-img {
  width: 500px;
}
.home-section-title {
  color: white;
  font-weight: bold;
  font-size: 30px;
}
.home-section-description {
  color: white;
  font-size: 13px;
}
.home-section-margin-top{
  margin-top: 120px
}
.ant-select-selection-placeholder {
  color: #f0f0f0 !important;
}
.ant-select-suffix {
  color: #f0f0f0 !important;
}
.search-box {
  ::placeholder {
    color: #fff !important;
  }
}
.ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-list-item-name {
  color: white;
}
.download-modal {
  .ant-modal-content {
    background-color: #171133;
    color: #FFFFFF;
  }
  .ant-modal-title {
    background-color: #171133;
    color: #FFFFFF;
  }
  .ant-modal-body {
    margin-top:5px;
    margin-bottom: 50px;
  }
  .ant-modal-close-x {
    color: #FFFFFF;
  }
}
