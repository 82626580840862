// TODO: need fix fonts attaching from lib
//@font-face {
//  font-family: HKGrotesk;
//  src: url('./assets/fonts/HKGrotesk-Regular.woff2') format('woff2'),
//  url('./assets/fonts/HKGrotesk-Regular.woff') format('woff');
//  font-weight: 400;
//  font-style: normal;
//}

h1, h2, h3, h4, h5 {
  color: $black;
  margin-bottom: 0;
}

h1, .h1 {
  font-size: 28px;
  font-weight: 700;
}
h2, .h2 {
  font-size: 25px;
  font-weight: 700;
}
h3, .h3 {
  font-size: 20px;
  font-weight: 700;
}
h4, .h4 {
  font-size: 20px;
  font-weight: 400;
}
h5, .h5 {
  font-size: 18px;
  font-weight: 500;
}

h6, .h6 {
  font-size: 18px;
  font-weight: 300;
  color: $blue;
}

p, .p {
  font-size: 15px;
  line-height: 1.375;
}

/* mixin for multiline */
@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white){
  overflow: hidden;
  position: relative;
  line-height: $lineHeight;
  max-height: $lineHeight * $lineCount;
  text-align: justify;
  margin-right: 0;
  padding-right: 1em;
  &::before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }
  &::after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: $bgColor;
  }
}
