.auth {
  display: flex;
  flex-direction: row;
}
.auth-section-header {
  background-repeat: no-repeat;
  background-image: url("../assets/auth_bg.jpg");
  background-color: #cccccc;
  background-size: cover;
  background-position: center;
  width: 50%;
  height: 100vh;
}
.auth-section-content {
  width: 50%;
}
.logo {
  width: 200px;
  height: 50px;
}
.auth-form {
  width: 65%;
  margin: auto;
}
.login-btn {
  min-width: 150px;
  height: 40px;
}
.login-options-button {
  background-color: #171133;
  border-color: #80CBD0;
  border-radius: 30px;
  height: 54px;
  width: 340px;
  border-width: 2px;
  margin-bottom: 20px;
  text-align: left;
  padding-left: 20px;
}
.login-input {
  background-color: rgb(139,136,153) !important;
  border-color: #707070 !important;
  color: #ffffff !important;
  border-radius: 5px !important;
  height: 50px;
}
.login-input::placeholder {
  color: #AEAEAE;
}
.login-form-input {
  .ant-form-item-required {
  }
}
