/**/
/* grid */
/**/

.grid-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px;
}
.grid-row--aic {
  align-items: center;
}
.grid-row--aib {
  align-items: flex-end;
}
.grid-row--jcc {
  justify-content: center;
}
.grid-row--jce {
  justify-content: flex-end;
}
.grid-row--jca {
  justify-content: space-around;
}
.grid-row--jcb {
  justify-content: space-between;
}
.grid-row--nog {
  margin-right: 0;
  margin-left: 0;
}
.grid-row--nog > .grid-col {
  padding-right: 0;
  padding-left: 0;
}
.grid-row--smg {
  margin-right: -4px;
  margin-left: -4px;
}
.grid-row--smg-add {
  margin-right: -4px;
  margin-left: -15px;
}
.grid-row--smg > .grid-col {
  padding-right: 4px;
  padding-left: 4px;
}
.grid-row--smg-add > .grid-col {
  padding-right: 4px;
  padding-left: 4px;
}
.grid-col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}
.grid-col--1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}
.grid-col--2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}
.grid-col--3 {
  flex: 0 0 25%;
  max-width: 25%;
}
.grid-col--4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.grid-col--5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}
.grid-col--6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.grid-col--7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}
.grid-col--8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}
.grid-col--9 {
  flex: 0 0 75%;
  max-width: 75%;
}
.grid-col--10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}
.grid-col--11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}
.grid-col--12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.grid-col--auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}


/**/
/* marings */
/**/
.mt--25 {
  margin-top: 4px;
}
.mt--50 {
  margin-top: 8px;
}
.mt--75 {
  margin-top: 12px;
}
.mt--100 {
  margin-top: 16px;
}
.mt--125 {
  margin-top: 20px;
}
.mt--150 {
  margin-top: 24px;
}
.mt--175 {
  margin-top: 28px;
}
.mt--200 {
  margin-top: 32px;
}
.mt--300 {
  margin-top: 48px;
}
.mt--400 {
  margin-top: 64px;
}


/**/
/* paragraphs */
/**/
.p--xs {
  font-size: 12px;
}
.p--ms {
  font-size: 13px;
}
.p--sm {
  font-size: 14px;
}
.p--lg {
  font-size: 18px;
}


/**/
/* alignment */
/**/
.ta--left {
  text-align: left;
}
.ta--center {
  text-align: center;
}
.ta--right {
  text-align: right;
}


/**/
/* font weights */
/**/
.fw-500 {
  font-weight: 500;
}
strong, .fw-700 {
  font-weight: 700;
}


/**/
/* text link */
/**/
.text-link {
  text-decoration: underline;
}
.text-link:hover {
  text-decoration: none;
}

/**/
/* colors */
/**/
.tc--light {
  color: rgba(30,37,43,0.6);
}
.tc--xlight {
  color: rgba(30,37,43,0.4);
}
.tc--primary {
  color: #D15E28;
}
.tc--secondary {
  color: #1379AC;
}
.tc--error {
  color: #C34E4F;
}
.tc--success {
  color: #00A44B;
}

/**/
/* FORM ELEMENTS */
/**/


/**/
/* label, hint */
/**/
.form-hint {
  margin-top: 8px;
  font-size: 12px;
  color: rgba(30,37,43,0.6);
}
.form-label {
  display: block;
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
}


/**/
/* input, select */
/**/
.form-input,
.form-select,
.form-textarea {
  display: block;
  width: 100%;
  padding: 9px 12px;
  outline: none;
  border-radius: 8px;
  border: 1px solid rgba(0,0,0,0.1);
  background-color: #707070;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.04);
  font-size: 15px;
  line-height: 20px;
  color: #1E252B;
  resize: none;
  transition-duration: 0.125s;
  transition-property: border-color, box-shadow;
  -webkit-appearance: none;

  &.input-with-close-icon {
    padding-right: 45px;
  }
}
.form-select {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="38" height="24" viewBox="0 0 24 24"><path fill="%231379AC" fill-opacity="0.5" d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"/></svg>');
  background-position: 100% 50%;
  background-repeat: no-repeat;
}
.form-input:hover,
.form-select:hover,
.form-textarea:hover {
  border-color: rgba(19,121,172,0.5);
}
.form-input:focus,
.form-select:focus,
.form-textarea:focus {
  border-color: #1379AC;
  box-shadow: 0 0 0 0 rgba(0,0,0,0.04);
}
.form-input::placeholder,
.form-textarea::placeholder {
  color: rgba(30,37,43,0.4);
  transition-duration: 0.125s;
}
.form-input:focus::placeholder,
.form-textarea:focus::placeholder {
  color: rgba(30,37,43,0);
}
.form-input[disabled],
.form-select[disabled],
.form-textarea[disabled] {
  background-color: rgba(0,0,0,0.02);
  box-shadow: none;
  pointer-events: none;
}
.form-input--datepicker {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="38" height="20" viewBox="0 0 24 24"><path fill="%231379AC" fill-opacity="0.5" d="M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z"/></svg>');
  background-position: 100% 50%;
  background-repeat: no-repeat;
}
.input:only-child .input-with-close-icon {
  padding-right: 12px;
}


/**/
/* radio, checkbox */
/**/
.form-radio,
.form-checkbox {
  position: relative;
  padding-left: 28px;
  font-size: 15px;
  line-height: 20px;
}
.form-radio__input,
.form-checkbox__input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  -webkit-appearance: none;
}
.form-radio__icon,
.form-checkbox__icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid rgba(0,0,0,0.1);
  pointer-events: none;
  transition-duration: 0.125s;
  transition-property: border-color, background-color;
}
.form-checkbox__icon {
  border-radius: 4px;
}
.form-radio__icon::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 6px;
  height: 6px;
  margin: -3px 0 0 -3px;
  border-radius: 50%;
  background-color: #FFF;
  opacity: 0;
  transition-duration: 0.125s;
  transition-property: background-color, opacity;
}
.form-checkbox__icon::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" stroke="%23FFFFFF" stroke-width="3" fill="none"><polyline points="20 6 9 17 4 12"></polyline></svg>') 50% 50% no-repeat;
}
.form-radio__input:hover ~ .form-radio__icon,
.form-checkbox__input:hover ~ .form-checkbox__icon {
  border-color: rgba(19,121,172,0.5);
}
.form-radio__input:checked ~ .form-radio__icon,
.form-checkbox__input:checked ~ .form-checkbox__icon {
  background-color: #1379AC;
  opacity: 1;
}
.form-radio__input:checked ~ .form-radio__icon::after,
.form-checkbox__input:checked ~ .form-checkbox__icon::after {
  opacity: 1;
}


/**/
/* card */
/**/
.form-card {
  position: relative;
  padding: 20px 16px 16px;
  border-radius: 8px;
  background-color: #FFF;
  box-shadow: 0 8px 12px 0 rgba(0,0,0,0.00), 0 2px 4px 0 rgba(0,0,0,0.05), 0 0 0 1px rgba(0,0,0,0.1) inset;
  text-align: center;
  transition-duration: 0.125s;
  transition-property: box-shadow;
}
.form-card__input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  -webkit-appearance: none;
}
.form-card__image {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  color: rgba(30,37,43,0.5);
  transition-duration: 0.125s;
  transition-property: color;
}
.form-card__label {
  display: block;
  margin-top: 8px;
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
  transition-duration: 0.125s;
  transition-property: color;
}
.form-card__sublabel {
  display: block;
  font-size: 12px;
  color: rgba(30,37,43,0.6);
  transition-duration: 0.125s;
  transition-property: color;
}
.form-card__icon {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 8px;
  box-shadow: 0 0 0 1px rgba(19,121,172,0) inset;
  pointer-events: none;
  transition-duration: 0.125s;
  transition-property: box-shadow, opacity;
}
.form-card__icon::before {
  content: '';
  position: absolute;
  top: 8px;
  right: 8px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid rgba(0,0,0,0.1);
}
.form-card__icon::after {
  content: '';
  position: absolute;
  top: 8px;
  right: 8px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #1379AC;
  background: #1379AC url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" stroke="%23FFFFFF" stroke-width="3" fill="none"><polyline points="20 6 9 17 4 12"></polyline></svg>') 50% 50% no-repeat;
  opacity: 0;
  transition-duration: 0.125s;
  transition-property: background-color, opacity;
}
.form-card:hover {
  box-shadow: 0 8px 12px 0 rgba(0,0,0,0.05), 0 2px 4px 0 rgba(0,0,0,0.05), 0 0 0 1px rgba(0,0,0,0.05) inset;
}
.form-card:hover .form-card__icon {
  box-shadow: 0 0 0 1px rgba(19,121,172,0.2) inset;
}
.form-card__input:checked ~ * {
  color: #1379AC;
}
.form-card__input:checked ~ .form-card__icon {
  box-shadow: 0 0 0 1px rgba(19,121,172,1) inset;
}
.form-card__input:checked ~ .form-card__icon::after {
  opacity: 1;
}
.form-cards-group {
  margin: -12px 0 0 -12px;
}
.form-cards-group .grid-col {
  padding: 12px 0 0 12px;
}


/**/
/* button */
/**/
.button {
  display: block;
  width: 100%;
  padding: 10px 32px;
  border-radius: 4px;
  border: 1px solid #D15E28;
  background-color: #FFF;
  font-size: 15px;
  line-height: 20px;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  color: #D15E28;
  cursor: pointer;
  -webkit-appearance: none;
  transition-duration: 0.125s;
  transition-property: border-color, background-color, color;
}
.button__icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  height: 20px;
}
.button__icon:last-child {
  margin-left: 4px;
  margin-right: -4px;
}
.button__icon:first-child {
  margin-right: 4px;
  margin-left: -4px;
}
.button__icon:first-child:last-child {
  margin-right: -4px;
  margin-left: -4px;
}
.button__counter {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  width: 20px;
  height: 20px;
  margin: 0 -4px 0 4px;
  border-radius: 50%;
  background-color: #FFF;
  font-size: 12px;
  line-height: 20px;
  font-weight: 700;
  text-align: center;
  color: #D15E28;

  &-gary {
    color: $text-gray-light;
    background-color: rgba($text-gray-light, 0.2);
  }
}
.button:hover,
.button:focus {
  background-color: rgba(209,94,40,0.05);
}
.button:active {
  background-color: rgba(209,94,40,0.15);
}
.button[disabled] {
  pointer-events: none;
  opacity: 0.3;
}
.button--sm {
  padding: 7px 24px;
  font-size: 14px;
}
.button--lg {
  padding: 15px 40px;
  font-size: 16px;
}
.button--ttu {
  text-transform: uppercase;
  letter-spacing: 1px;
}
.button--primary {
  background-color: #D15E28;
  color: #FFF;
}
.button--primary:hover,
.button--primary:focus {
  border-color: #C05422;
  background-color: #C05422;
}
.button--primary:active {
  border-color: #AE4818;
  background-color: #AE4818;
}
.button--secondary {
  border-color: transparent;
  background-color: rgba(209,94,40,0.2);
}
.button--secondary:hover,
.button--secondary:focus {
  background-color: rgba(209,94,40,0.3);
}
.button--secondary:active {
  background-color: rgba(209,94,40,0.4);
}
.button--link {
  padding: 0;
  border: none;
  background: none;
  font-size: 16px;
  line-height: 1.25;
  font-weight: 400;
  text-decoration: underline;
  color: rgba(30,37,43,0.7);
}
.button--link:hover,
.button--link:focus {
  background: none;
  color: rgba(209,94,40,0.9);
}
.button--link:active {
  color: rgba(209,94,40,1);
}
.button-eye {
  position: absolute;
  top: 45px;
  right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  transform: translateY(-50%);
}




/**/
/* LAYOUT */
/**/


/**/
/* header */
/**/
.header {
  padding: 8px 16px;
  background-color: #1379AC;
  color: #FFF;
}


/**/
/* logo */
/**/
.logo {
  display: inline-block;
  vertical-align: top;
}
.logo img {
  display: block;
}


/**/
/* main nav */
/**/
.main-nav {
  display: flex;
}
.main-nav__link {
  margin: 0 12px;
  padding: 6px 8px;
  border-radius: 4px;
  font-size: 15px;
  line-height: 20px;
  text-decoration: none;
  transition-duration: 0.125s;
  transition-property: background-color;
}
.main-nav__link:hover {
  background-color: #0B5F95;
}
.main-nav__link.active {
  background-color: #0B5F95;
  font-weight: 700;
}


/**/
/* notification nav */
/**/
.notification-nav__toggle {
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="%23FFF" d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.89 2 2 2zm6-6v-5c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2z"/></svg>');
}
.notification-nav__toggle span {
  position: absolute;
  top: -6px;
  right: -4px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #1379AC;
  background-color: #D15E28;
  font-size: 9px;
  line-height: 12px;
  font-weight: 700;
  text-align: center;
}


/**/
/* user nav */
/**/
.user-nav__toggle {
  display: block;
  font-size: 15px;
  line-height: 24px;
}
.user-nav__toggle img {
  vertical-align: top;
  margin-left: 8px;
  border-radius: 50%;
}


/**/
/* sidebar */
/**/
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 220px;
  padding: 28px;
  background-color: #FFF;
}
.uikit__example .sidebar {
  position: static;
  margin: -24px;
}


/**/
/* side nav */
/**/
.side-nav {
  margin-top: 44px;
  font-size: 18px;
}
.side-nav__link {
  display: block;
  padding: 4px 0;
  text-decoration: none;
  color: #6E8590;
  transition-duration: 0.125s;
}
.side-nav__link:hover {
  color: #1E252B;
}
.side-nav__link--active {
  font-weight: 700;
  color: #1E252B;
}
.side-nav__counter {
  display: inline-block;
  vertical-align: top;
  margin-left: 8px;
  padding: 0 8px;
  border-radius: 10px;
  background-color: #EF471F;
  font-size: 11px;
  line-height: 20px;
  font-weight: 700;
  color: #FFF;
}
.side-nav__divider {
  margin: 20px 0;
  border-top: 1px solid rgba(0,0,0,0.05);
}




/**/
/* COMPONENTS */
/**/


/**/
/* alert */
/**/
.alert {
  padding: 15px;
  border-radius: 8px;
  background-color: rgba(35,146,209,0.05);
  border: 1px solid rgba(35,146,209,0.5);
  font-weight: 500;
  text-align: center;
  color: #2392D1;
}
.alert--warning {
  background-color: rgba(234,107,39,0.05);
  border: 1px solid rgba(234,107,39,0.5);
  color: #EA6B27;
}
.alert--success {
  background-color: rgba(95,171,118,0.05);
  border: 1px solid rgba(95,171,118,0.5);
  color: #5FAB76;
}


/**/
/* badges */
/**/
.badge {
  padding: 0 6px;
  border-radius: 2px;
  border: 1px solid transparent;
  font-size: 12px;
  line-height: 1.334;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
.badge--lg {
  padding: 1px 8px;
  font-size: 14px;
}
.badge--ecommerce {
  background-color: rgba(234,107,39,0.20);
  border-color: rgba(234,107,39,0.70);
  color: #EA6B27;
}
.badge--event {
  background-color: rgba(180,137,197,0.20);
  border-color: rgba(180,137,197,0.70);
  color: #B489C5;
}
.badge--group {
  background-color: rgba(35,146,209,0.20);
  border-color: rgba(35,146,209,0.70);
  color: #2392D1;
}
.badge--preview {
  background-color: #1379ac;
  border-radius: 2px;
  color: #fff;
}
.badge--match {
  width: 40px;
  padding-right: 0;
  padding-left: 0;
  background-color: #B1BDD2;
  color: #FFF;
}
.badge--match-10 {
  background-color: #CF7676;
}
.badge--match-20 {
  background-color: #E58686;
}
.badge--match-30 {
  background-color: #E58D86;
}
.badge--match-40 {
  background-color: #E59B86;
}
.badge--match-50 {
  background-color: #E5B386;
}
.badge--match-60 {
  background-color: #E5D286;
}
.badge--match-70 {
  background-color: #B7D26B;
}
.badge--match-80 {
  background-color: #92C470;
}
.badge--match-90 {
  background-color: #70C485;
}
.badge--match-100 {
  background-color: #64AED8;
}
.badge--match.badge--lg {
  width: 108px;
}
.badge--status {
  min-width: 100px;
  padding: 4px;
  border-radius: 12px;
  background-color: #B1BDD2;
  font-size: 11px;
  text-align: center;
  color: #FFF;
}
.badge--status-paid {
  background-color: #A4B9C3;
}
.badge--status-unpaid {
  background-color: #D15E28;
}
.badge--status-pending {
  background-color: #E5B086;
}
.badge--status-approved {
  background-color: #8FCBA1;
}
.badge--status-running {
  background-color: #64AED8;
}
.badge--status-rejected {
  background-color: #EF471F;
}
.badge--status-review {
  background-color: rgba(239,71,31,0.15);
  color: #EF471F;
}

.qu-discount-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $green-alt;
  border: 1px solid $light-green;
  border-radius: 10px;
  padding: 10px;
  background-color: rgba(158,223,173,0.13);
  font-size: 15px;
  font-weight: 800;
  line-height: 19px;

  svg {
    margin-right: 8px;
    width: 16px;
    color: $light-green;
  }
}


/**/
/* cards */
/**/
.card {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  background-color: #FFF;
  box-shadow: 0 8px 12px 0 rgba(0,0,0,0.00), 0 2px 4px 0 rgba(0,0,0,0.05), 0 0 0 1px rgba(0,0,0,0.05) inset;
}
.card__head {
  position: relative;
  padding-top: 56.25%;
}
.card__head--43 {
  padding-top: 75%;
}
.card__cont {
  padding: 17px 16px 20px;
}
.card__foot {
  padding: 8px 16px;
  background-color: #F9F9F9;
}
.card__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card__day {
  position: relative;
  float: left;
  width: 64px;
  margin-top: -24px;
  margin-right: 16px;
  padding: 7px 0;
  border-radius: 8px;
  border: 2px solid #FFF;
  background-color: #B489C5;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
  font-weight: 700;
  text-align: center;
  color: #FFF;
}
.card__day span {
  display: block;
  font-size: 12px;
  letter-spacing: 1px;
  opacity: 0.7;
}
.card__logo {
  position: relative;
  float: left;
  width: 64px;
  height: 64px;
  margin-top: -24px;
  margin-right: 16px;
  border-radius: 50%;
  border: 2px solid #FFF;
  background-color: #FFF;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
}
.card__logo img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.card__summary {
  margin-top: 12px;
}
.card__price {
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-right: 16px;
  font-size: 14px;
  font-weight: 700;
}
.card__price img {
  margin-right: 8px;
}
.card__price span {
  margin-top: 1px;
}
.card__badge {
  margin-top: 16px;
  margin-left: 4px;
}
.card__meta {
  margin-right: 16px;
  padding-left: 18px;
  font-size: 12px;
  line-height: 1.334;
}
.card__meta--users {
  padding-left: 21px;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="12" viewBox="0 0 640 512"><path fill="%23C4C4C4" d="M192 256c61.9 0 112-50.1 112-112S253.9 32 192 32 80 82.1 80 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C51.6 288 0 339.6 0 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zM480 256c53 0 96-43 96-96s-43-96-96-96-96 43-96 96 43 96 96 96zm48 32h-3.8c-13.9 4.8-28.6 8-44.2 8s-30.3-3.2-44.2-8H432c-20.4 0-39.2 5.9-55.7 15.4 24.4 26.3 39.7 61.2 39.7 99.8v38.4c0 2.2-.5 4.3-.6 6.4H592c26.5 0 48-21.5 48-48 0-61.9-50.1-112-112-112z"></path></svg>') 0 50% no-repeat;
}
.card__meta--views {
  padding-left: 18.5px;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="12" viewBox="0 0 576 512"><path fill="%23C4C4C4" d="M288 144a110.94 110.94 0 0 0-31.24 5 55.4 55.4 0 0 1 7.24 27 56 56 0 0 1-56 56 55.4 55.4 0 0 1-27-7.24A111.71 111.71 0 1 0 288 144zm284.52 97.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400c-98.65 0-189.09-55-237.93-144C98.91 167 189.34 112 288 112s189.09 55 237.93 144C477.1 345 386.66 400 288 400z"></path></svg>') 0 50% no-repeat;
}
.card__meta--twitter {
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="12" viewBox="0 0 512 512"><path fill="%23C4C4C4" d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path></svg>') 0 50% no-repeat;
}
.card__meta--facebook {
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="12" viewBox="0 0 512 512"><path fill="%23C4C4C4" d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"></path></svg>') 0 50% no-repeat;
}
.card__input {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  -webkit-appearance: none;
}
.card__checkmark {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 8px;
  box-shadow: 0 0 0 1px #1379AC inset;
  pointer-events: none;
  opacity: 0;
  transition-duration: 0.125s;
  transition-property: opacity;
}
.card__checkmark::after {
  content: '';
  position: absolute;
  top: 8px;
  right: 8px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #1379AC;
  background: #1379AC url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" stroke="%23FFFFFF" stroke-width="3" fill="none"><polyline points="20 6 9 17 4 12"></polyline></svg>') 50% 50% no-repeat;
  opacity: 0;
  transition-duration: 0.125s;
  transition-property: background-color, opacity;
}

/**/
/* forms */
/**/
.form__hr {
  margin: 20px 0;
  border-top: 1px solid rgba(0,0,0,0.05);
}
.form__head {
  display: flex;
  align-items: center;
  padding: 16px 24px;
  border-radius: 8px 8px 0 0;
  background-color: rgba(246,247,248,0.5);
  border-bottom: 1px solid rgba(0,0,0,0.05);
}
.form__cont {
  padding: 20px 24px;
}
.form__number {
  width: 28px;
  height: 28px;
  margin-right: 16px;
  border-radius: 50%;
  background-color: rgba(110,133,144,0.2);
  font-size: 14px;
  line-height: 28px;
  font-weight: 700;
  text-align: center;
  color: #6E8590;

  span {
    display: none;
  }
}
.form--vertical .form-label {
  margin-bottom: 8px;
}
.form--vertical .form-input {
  margin-bottom: 24px;
}
.form--horizontal {
  border-radius: 8px;
  border: 1px solid rgba(0,0,0,0.05);
  background-color: $white;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.08);
}
.form--horizontal .form-checkbox {
  margin: 8px 0;
}

/**/
/* tables */
/**/
.table {
  overflow: hidden;
  border-radius: 10px;
  background: #FFFFFF;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  font-size: 13px;
  line-height: 1.375;
}
.table__table {
  width: 100%;
  border-collapse: collapse;
}
.table__th {
  padding: 12px 0 12px 20px;
  background: rgba(246,247,248,0.5);
  font-size: 13px;
  font-weight: 700;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: rgba(30,37,43,0.6);
}
.table__th--center {
  text-align: center;
}
.table__th:last-child {
  padding-right: 20px;
}
.table__td {
  vertical-align: middle;
  padding: 20px 0 20px 20px;
}
.table__td--1 {
  width: 1%;
}
.table__service-icon {
  display: block;
  margin-right: -8px;
}
.table__td--inner {
  vertical-align: bottom;
  padding-top: 6px;
  padding-right: 8px;
  padding-bottom: 6px;
}
.table__td:last-child {
  padding-right: 20px;
}
.table__tr--dark {
  background: rgba(246,247,248,0.5);
}
.table__tr--inner .table__td--inner {
  vertical-align: top;
  border-top: 1px solid rgba(0,0,0,0.05);
}
.table__tr--inner .table__td--inner:last-child {
  padding-right: 8px;
}
.table__tr--completed > td {
  opacity: 0.6;
}
.table__tr--api {
  font-family: Monaco, monospace;
  font-size: 12px;
}
.table__tr--api td {
  padding-top: 12px;
  padding-bottom: 12px;
  border-top: 1px solid rgba(0,0,0,0.05);
}
.table__tr--api:nth-child(even) {
  background: rgba(246,247,248,0.5);
}
.table__image {
  border-radius: 50%;
}
.table__service {
  padding-left: 24px;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="%239EDFAD" d="M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M7,11.4L3.6,8L5,6.6l2,2l4-4L12.4,6L7,11.4z"></path></svg>') no-repeat;
}
.table__service--completed {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="%23B1BDD2" d="M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M7,11.4L3.6,8L5,6.6l2,2l4-4L12.4,6L7,11.4z"></path></svg>');
}
.table__dots {
  position: relative;
  display: block;
  width: 24px;
  height: 24px;
  margin: 0 -10px;
}
.table__dots::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 4px;
  height: 4px;
  margin: -2px 0 0 -2px;
  border-radius: 4px;
  background-color: #5B6475;
  box-shadow: 0 6px 0 0 #5B6475, 0 -6px 0 0 #5B6475;
  opacity: 0.5;
  transition-duration: 0.125s;
  transition-property: background-color, box-shadow, opacity;
}
.table__dots:hover::after {
  background-color: #D15E28;
  box-shadow: 0 6px 0 0 #D15E28, 0 -6px 0 0 #D15E28;
  opacity: 1;
}


/**/
/* popups */
/**/
.popup {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  background: rgba(30,37,43,0.8);
  pointer-events: none;
  opacity: 0;
  transition-duration: 0.25s;
  transition-property: opacity;
}
.popup.active {
  pointer-events: auto;
  opacity: 1;
}
.popup__tbl {
  display: table;
  width: 100%;
  height: 100%;
}
.popup__tcl {
  display: table-cell;
  vertical-align: middle;
  padding: 60px;
}
.popup__frame {
  position: relative;
  max-width: 400px;
  margin: 0 auto;
  border-radius: 10px;
  border: 1px solid rgba(0,0,0,0.05);
  background: #FFFFFF;
  box-shadow: 0 16px 24px 0 rgba(37,62,69,0.1), 0 2px 4px 0 rgba(0,0,0,0.08);
}
.popup__frame--lg {
  max-width: 678px;
  border-radius: 20px;
}
.popup__close {
  position: absolute;
  bottom: 100%;
  left: 100%;
  width: 44px;
  height: 44px;
}
.popup__close::after,
.popup__close::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 28px;
  height: 2px;
  background-color: #FFF;
  margin: -1px 0 0 -14px;
  transform: rotate(45deg);
}
.popup__close::before {
  transform: rotate(-45deg);
}
.popup__head {
  padding: 20px 28px;
  border-radius: 10px 10px 0 0;
  background-color: rgba(246,247,248,0.5);
}
.popup__cont {
  padding: 20px 28px 28px;
}

/**/
/* tree */
/**/
.qu-tree {
  position: relative;

  &-controls {
    display: flex;
    margin-bottom: 30px;

    .qu-button {
      width: 166px;
      margin-right: 10px;
    }

    .expand-group {
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;

      button {
        background-color: transparent;
        border: none;
        color: $primary;
        padding: 0 20px;
        cursor: pointer;

        &:hover {
          color: $primaryButtonHover;
        }

        &:first-child {
          padding-left: 10px;
          border-right: 1px solid $border-alt;
        }
      }
    }
  }

  &-list {
    overflow: hidden;
    border-radius: 10px;
  }

  &-item {
    border: 1px solid $border-alt;
    background: white;
    border-radius: 10px;
    box-shadow: $inputShadow;
    overflow: hidden;
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.vertical-top {
      align-items: flex-start;
    }
  }

  &-label {
    color: $text-gray-light;
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
  }

  &-row {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 16px;
    border-top: 1px solid $border-alt;

    &:nth-child(odd) {
      background-color: $main-bg;
    }

    .cell {
      font-size: 14px;
      line-height: 17px;

      &-name {
        font-weight: bold;
        margin-right: 100px;
        margin-left: 39px;
        min-width: 120px;
      }

      &-email {
        color: rgba(#212930, 0.6);
      }

      &-controls {
        display: flex;
        margin-left: auto;

        svg {
          opacity: 0.6;
          cursor: pointer;

          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }

  .ant-collapse {

    &-arrow {
      color: rgba($text-gray-light, 0.6) !important;
      font-size: 24px !important;
    }

    &-header {
      padding: 10px 10px 10px 55px !important;
    }

    &-content {

      &-box {
        padding: 0 !important;
      }
    }
  }

  &-branch {
    padding: 0;
    margin: 0;
    list-style-type: none;
    position: relative;

    & > li:last-child {
      border-left: 1px solid transparent;
    }
  }

  &-leaf {
    list-style-type: none;
    border-left: 1px solid $border-alt;
    padding-top: 30px;
    margin-left: 52px;

    &.level-0 {
      margin-left: -24px;
      border-left: none;

      &:first-child {
        padding-top: 0;
      }

      > .leaf-wrapper {

        &:before {
          border-width: 0;
        }
      }

      &:not(:first-child) {

        > .leaf-wrapper {

          &:before {
            bottom: 100%;
            width: 30px;
            left: 22px;
            border-right: 1px solid $border-alt;
          }
        }
      }
    }

    .leaf-wrapper {
      padding-left: 22px;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: -31px;
        left: -1px;
        bottom: calc(100% - 30px);
        width: 23px;
        border: 1px solid $border-alt;
        border-top: 0 none transparent;
        border-right: 0 none transparent;
      }
    }
  }

  @media only screen and (max-width: 666px) {

    &-controls {
      flex-direction: column;

      .qu-button {
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
      }

      .expand-group {
        margin-top: 20px;

        button {

          &:first-child {
            padding-left: 20px;
          }
        }
      }
    }
  }
}

/**/
/* api plan */
/**/
.api-plan {
  overflow: hidden;
  border-radius: 10px;
  background-color: #FAFBFB;
  border: 1px solid rgba(0,0,0,0.05);
}
.api-plan__head {
  padding: 20px 24px;
  background-color: #FFF;
}
.api-plan__subtitle {
  margin-top: 4px;
}
.api-plan__cont {
  padding: 20px 24px;
}
.api-plan__label {
  margin-bottom: 12px;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.api-plan__list {
  list-style: none;
  line-height: 20px;
}
.api-plan__list li {
  margin-top: 8px;
  padding-left: 28px;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M12,0A12,12,0,1,0,24,12,12.035,12.035,0,0,0,12,0ZM10,17.414,4.586,12,6,10.586l4,4,8-8L19.414,8Z" fill="%239EDFAD"></path></svg>') no-repeat;
}
.api-plan__note {
  margin-top: 20px;
  padding-top: 16px;
  border-top: 1px solid rgba(0,0,0,0.05);
}


/**/
/* profile completion */
/**/
.profile-completion {
  overflow: hidden;
  border-radius: 10px;
  background-color: #FFFFFF;
  border: 1px solid rgba(0,0,0,0.05);
}
.profile-completion__head {
  padding: 20px 24px;
  background-color: rgba(246,247,248,0.5);
}
.profile-completion__title {
  font-weight: 700;
}
.profile-completion__percent {
  float: right;
  font-weight: 700;
  color: #FFA652;
}
.profile-completion__percent--100 {
  color: #5CC073;
}
.profile-completion__cont {
  padding: 24px;
}
.profile-completion__item {
  position: relative;
  margin-bottom: 16px;
  border-radius: 10px;
  border: 2px solid rgba(177,189,210,0.5);
}
.profile-completion__icon {
  position: absolute;
  top: 8px;
  left: -17px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 2px solid #B1BDD2;
  background-color: #FFF;
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
  text-align: center;
  color: #B1BDD2;
}
.profile-completion__icon--check {
  background: #9EDFAD url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 16 16"><polygon fill="%23FFFFFF" points="5.6,9.6 2.4,6.4 0,8.8 5.6,14.4 16,4 13.6,1.6 "></polygon></svg>') 50% 50% no-repeat;
  font-size: 0;
}
.profile-completion__icon--error {
  background: #FFA792 url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 16 16"><polygon points="9.5 1 6.5 1 7 10 9 10 9.5 1" fill="%23FFFFFF"></polygon><circle data-color="color-2" cx="8" cy="13.5" r="1.5" fill="%23FFFFFF"></circle></svg>') 50% 50% no-repeat;
  font-size: 0;
}
.profile-completion__label {
  padding: 14px 14px 14px 30px;
  font-weight: 700;
  color: #6E8590;
}
.profile-completion__ul {
  list-style: none;
  display: none;
  margin-top: -2px;
  padding: 0 0 14px 32px;
  font-size: 13px;
  line-height: 16px;
  color: #6E8590;
}
.profile-completion__li {
  margin-top: 6px;
  padding-left: 24px;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="%23D0D7E4" d="M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M11.5,10.1l-1.4,1.4L8,9.4l-2.1,2.1l-1.4-1.4L6.6,8 L4.5,5.9l1.4-1.4L8,6.6l2.1-2.1l1.4,1.4L9.4,8L11.5,10.1z"></path></svg>') 0 0 no-repeat;
}
.profile-completion__li--passed {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="%239EDFAD" d="M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M7,11.4L3.6,8L5,6.6l2,2l4-4L12.4,6L7,11.4z"></path></svg>');
  color: #5CC073;
}
.profile-completion__li:first-child {
  margin-top: 0;
}
.profile-completion__note {
  margin-top: 20px;
  padding-top: 16px;
  border-top: 1px solid rgba(0,0,0,0.05);
  font-size: 13px;
}
.profile-completion__item--passed {
  border-color: rgba(158,223,173,0.50);
  background-color: rgba(158,223,173,0.125);
}
.profile-completion__item--passed .profile-completion__icon {
  border-color: #9EDFAD;
  color: #5CC073;
}
.profile-completion__item--passed .profile-completion__label {
  color: #5CC073;
}
.profile-completion__item--failed {
  border-color: rgba(255,167,146,0.50);
  background-color: rgba(255,167,146,0.125);
}
.profile-completion__item--failed .profile-completion__icon {
  border-color: #FFA792;
  color: #FFA792;
}
.profile-completion__item--failed .profile-completion__label {
  color: #FF957B;
}
.profile-completion__item--opened {
  background-color: transparent;
}
.profile-completion__item--opened .profile-completion__ul {
  display: block;
}


/**/
/* cart summary */
/**/
.cart-summary {
  padding: 20px 24px;
  border-radius: 10px;
  background-color: #FFFFFF;
  border: 1px solid rgba(0,0,0,0.05);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
}
.cart-summary__edit {
  float: right;
  margin: 3px 0;
  text-decoration: underline;
}
.cart-summary__edit:hover {
  text-decoration: none;
}
.cart-summary__heading {
  margin-bottom: 12px;
  font-weight: 500;
}
.cart-summary__ulist {
  list-style: none;
  line-height: 20px;
}
.cart-summary__ulist li {
  margin-top: 4px;
  padding-left: 24px;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="%239EDFAD" d="M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M7,11.4L3.6,8L5,6.6l2,2l4-4L12.4,6L7,11.4z"></path></svg>') 0 2px no-repeat;

  &.disabled {
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="%23D0D7E4" d="M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M11.5,10.1l-1.4,1.4L8,9.4l-2.1,2.1l-1.4-1.4L6.6,8 L4.5,5.9l1.4-1.4L8,6.6l2.1-2.1l1.4,1.4L9.4,8L11.5,10.1z"></path></svg>') 0 2px no-repeat;
  }
}
.cart-summary__dlist {
  font-size: 13px;
}
.cart-summary__dlist dt {
  float: left;
  width: 85px;
  opacity: 0.5;
}
.cart-summary__dlist dd {
  overflow: hidden;
  margin-bottom: 8px;
  min-height: 20px;
}
.cart-summary__dlist dd:last-child {
  margin-bottom: 0;
}
.cart-summary__divider {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid rgba(0,0,0,0.05);
}
.cart-summary__reach {
  margin-top: 24px;
  padding: 11px 15px;
  border-radius: 4px;
  background-image: linear-gradient(180deg, rgba(255,255,255,0.05) 0%, rgba(186,235,214,0.10) 100%);
  border: 1px solid rgba(0,0,0,0.05);
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);
  text-align: center;
}
.cart-summary__value {
  margin-top: 2px;
}

.qu-summary {

  &-popover {

    .ant-popover-inner {
      box-shadow: none;
      background-color: transparent;

      &-content {
        padding: 0;
      }
    }

    .cart {

      &-summary {
        max-width: 285px;
        width: 285px;
        max-height: 85vh;
        border: none;
        box-shadow: 0 24px 35px 0 rgba(0,34,51,0.21);
        overflow: auto;
      }
    }
  }

  &-button {
    position: absolute;
    top: 0;
    right: 8px;
    display: none;
    align-items: center;
    justify-content: center;
    height: 45px;
    width: 45px;
    color: $blue;
    border: 1px solid rgba(0,0,0,0.05);
    border-radius: 50%;
    background-color: $white;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.08), 0 10px 14px 0 rgba(0,61,92,0.14);

    svg {
      width: 24px;
    }
  }
}

.qu-potential {
  margin-bottom: 16px;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  color: $white;
  background-color: $blue;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.04);

  &-statistic {

    p {
      margin-bottom: 0;
    }

    h4 {
      margin-bottom: 5px;
      font-size: 24px;
      color: $white;
      letter-spacing: 1.33px;
      font-weight: 600;
    }
  }

  &-desc {
    padding-top: 10px;
    border-top: 1px solid rgba(246, 247, 248, 0.1);

    p {
      margin-bottom: 0;
      color: rgba($white, 0.8);
      font-size: 13px;
      line-height: 15px;
    }
  }

  @media only screen and (max-width: 666px) {
    padding: 16px;

    &-statistic {

      h4 {
        margin-bottom: 0;
      }
    }

    &-desc {
      display: none;
    }
  }
}


/**/
/* discount submit */
/**/
.discount-submit {
  padding: 48px 72px 24px;
  margin: -24px;
  border-radius: 20px;
  background-color: rgba(246,247,248,0.5);
  text-align: center;
}
.discount-submit__title {
  max-width: 400px;
  margin-right: auto;
  margin-left: auto;
  line-height: 33px;
}
.discount-submit__form {
  max-width: 340px;
  margin-right: auto;
  margin-left: auto;
}
.discount-submit__icon {
  display: block;
  margin: 0 auto;
}

@media only screen and (max-width: 666px) {
  .discount-submit {
    padding: 48px 20px 24px;

    &__title.h1 {
      font-size: 26px;

      + .p--lg {
        font-size: 15px;
        margin-top: 16px;
        margin-bottom: 32px;
      }
    }
  }
}


/**/
/* discount thanks */
/**/
.discount-thanks {
  padding: 48px 80px;
  border-radius: 20px;
  background-color: rgba(246,247,248,0.5);
  text-align: center;
}
.discount-thanks__title {
  max-width: 420px;
  margin-right: auto;
  margin-left: auto;
  font-size: 34px;
}
.discount-thanks__subtitle {
  font-size: 24px;
}


/**/
/* actions popover */
/**/
.actions-popover {
  width: 150px;
  border-radius: 0 10px 10px 10px;
  border: 1px solid rgba(0,0,0,0.05);
  background: #FFFFFF;
  box-shadow: 0 16px 24px 0 rgba(37,62,69,0.1), 0 2px 4px 0 rgba(0,0,0,0.08);
}
.actions-popover__link {
  display: block;
  font-size: 15px;
  line-height: 36px;
  text-decoration: none;
  color: #5B6475;
  transition-duration: 0.125s;
  transition-property: color;
}
.actions-popover__link:last-child {
  margin-top: 8px;
  border-top: 1px solid rgba(0,0,0,0.05);
}
.actions-popover__link:hover {
  color: #1379AC;
}
.actions-popover__icon {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  width: 44px;
  height: 36px;
  border-left: 4px solid transparent;
  opacity: 0.6;
  transition-duration: 0.125s;
  transition-property: opacity;
}
.actions-popover__link:hover .actions-popover__icon {
  opacity: 1;
}


/**/
/* prices popover */
/**/
.prices-popover {
  position: relative;
  width: 240px;
  padding: 19px;
  border-radius: 10px;
  border: 1px solid rgba(0,0,0,0.05);
  background: #FFFFFF;
  box-shadow: 0 16px 24px 0 rgba(37,62,69,0.1), 0 2px 4px 0 rgba(0,0,0,0.08);
}
.prices-popover::after {
  content: '';
  position: absolute;
  top: 24px;
  left: -8px;
  width: 14px;
  height: 14px;
  border: 1px solid rgba(0,0,0,0.05);
  border-right-width: 0;
  border-bottom-width: 0;
  border-top-left-radius: 2px;
  background: #FFFFFF;
  transform: rotate(-45deg);
}
.prices-popover__icon {
  display: block;
}
.prices-popover__heading {
  margin-top: 24px;
}
.prices-popover__heading:first-child {
  margin-top: 0;
}
.prices-popover__caption {
  margin-top: 12px;
  margin-bottom: -4px;
}
.prices-popover__list {
  margin-top: 8px;
}
.prices-popover__list dt {
  float: left;
}
.prices-popover__list dd {
  margin-bottom: 4px;
  font-weight: 700;
  text-align: right;
}
.prices-popover__list dd:last-child {
  margin-bottom: 0;
}


/**/
/* match popover */
/**/
.match-popover {
  position: relative;
  width: 240px;
  padding: 19px;
  border-radius: 10px;
  border: 1px solid rgba(0,0,0,0.05);
  background: #FFFFFF;
  box-shadow: 0 16px 24px 0 rgba(37,62,69,0.1), 0 2px 4px 0 rgba(0,0,0,0.08);
}
.match-popover::after {
  content: '';
  position: absolute;
  top: 24px;
  left: -8px;
  width: 14px;
  height: 14px;
  border: 1px solid rgba(0,0,0,0.05);
  border-right-width: 0;
  border-bottom-width: 0;
  border-top-left-radius: 2px;
  background: #FFFFFF;
  transform: rotate(-45deg);
}
.match-popover__title {
  font-weight: 700;
  letter-spacing: 0.5px;
  color: #63ACD6;
}
.match-popover__list {
  list-style: none;
}
.match-popover__list li {
  margin-top: 12px;
  padding-left: 28px;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="%239EDFAD" d="M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M7,11.4L3.6,8L5,6.6l2,2l4-4L12.4,6L7,11.4z"></path></svg>') 0 1px no-repeat;
}
.match-popover__list ul {
  list-style: none;
  margin-top: 8px;
  font-weight: 400;
}
.match-popover__list ul li {
  position: relative;
  margin-top: 2px;
  padding-left: 0;
  background: none;
}
.match-popover__list ul li::after {
  content: '';
  position: absolute;
  width: 4px;
  height: 4px;
  top: 6px;
  right: 100%;
  margin-right: 18px;
  border-radius: 50%;
  background: currentcolor;
}

/**/
/* notifications */
/**/
.notifications {
  position: relative;
  width: 280px;
  border-radius: 10px;
  border: 1px solid rgba(0,0,0,0.05);
  background: #FFFFFF;
  box-shadow: 0 16px 24px 0 rgba(37,62,69,0.1), 0 2px 4px 0 rgba(0,0,0,0.08);
}
.notifications::after {
  content: '';
  position: absolute;
  top: -8px;
  right: 24px;
  width: 14px;
  height: 14px;
  border: 1px solid rgba(0,0,0,0.05);
  border-right-width: 0;
  border-bottom-width: 0;
  border-top-left-radius: 2px;
  background: #F8F9FA;
  transform: rotate(45deg);
}
.notifications__title {
  padding: 9px 15px;
  background: #F8F9FA;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #6E8590;
}
.notifications__cont {
  padding: 0 15px 15px 15px;
}
.notifications__item {
  position: relative;
  padding-left: 56px;
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid rgba(0,0,0,0.05);
}
.notifications__item:first-child {
  margin-top: 0;
  border-top: none;
}
.notifications__icon {
  position: absolute;
  top: 16px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #FFF;
}
.notifications__icon--red {
  background-color: #EA6B27;
}
.notifications__icon--blue {
  background-color: #2392D1;
}
.notifications__icon--green {
  background-color: #9EDFAD;
}
.notifications__text strong {
  color: #1E252B;
}
.notifications__date {
  margin-top: 4px;
  font-size: 9px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

/**/
/* Brief form */
/**/
.form.form-brief {

  .form-brief {

    &-footer {
      display: none;
    }

    &-subtitle {
      font-size: 15px;
      font-weight: 500;
      line-height: 19px;
    }
  }

  @media only screen and (max-width: 666px) {
    position: relative;
    background-color: transparent;
    box-shadow: none;
    border: none;

    .form {

      &__head {
        flex-direction: column;
        align-items: flex-start;
        padding: 0 0 16px;

        h3 {
          font-size: 28px;
          line-height: 33px;
          max-width: 295px;
        }
      }

      &__number {
        width: initial;
        height: initial;
        margin-right: 0;
        border-radius: 0;
        margin-bottom: 5px;
        background-color: transparent;
        font-size: 16px;
        line-height: 19px;
        font-weight: normal;
        text-align: left;
        color: $blue;

        span {
          display: inline;
        }
      }

      &__cont {
        padding: 20px 0;

        .qu-distributing-popover {
          display: none;
        }

        .qu-banner-popover {
          display: none;
        }
      }

      &-label {
        margin-top: 0;
        margin-bottom: 6px;
      }
    }

    .qu-summary-button {
      display: flex;

      span {
        height: 24px;
      }

      .close-icon {
        display: none;
      }

      &.ant-popover-open {
        background-color: $light-gray;


        .list-icon {
          display: none;
        }

        .close-icon {
          display: inline;
        }
      }
    }

    .form-brief {

      &-footer {
        display: block;
      }
    }
  }
}

.qu-brand {

  &-label {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  &-logo {
    display: flex;
    width: 62px;
    min-width: 62px;
    height: 62px;
    border: 2px solid $white;
    border-radius: 50%;
    background-color: $light-gray;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
    margin-right: 16px;
    overflow: hidden;

    img {
      height: 100%;
      width: auto;
      object-fit: contain;
    }
  }

  &-info {
    max-width: 200px;
  }

  &-name {
    font-size: 17px;
    line-height: 21px;
  }

  &-subtitle {
    color: rgba($text-black, 0.6);
    font-size: 13px;
    line-height: 19px;
    margin-bottom: 0;
  }
}

.qu-details {

  &-table {
    position: relative;
    z-index: 1;

    .ant-table {
      background-color: transparent;

      &-thead {
        text-transform: uppercase;
        font-size: 13px;
        letter-spacing: 1px;

        .ant-table-cell {
          color: $text-gray;
          font-weight: 600;
          background-color: transparent;
        }
      }

      &-row {

        &.ant-table-row-selected {

          .ant-table-cell {
            background-color: transparent;
          }

          .cell-services {
            color: $blue;
          }
        }

        &:hover > td {
          color: $text-black;

          &.ant-table-cell {
            background: transparent;
          }

          .cell-pricing {

            span {
              opacity: 1;
            }
          }
        }
      }
    }

    .cell-services {
      font-size: 17px;
      font-weight: 500;
    }

    .cell-pricing {
      font-size: 15px;
      font-weight: 500;

      span {
        font-size: 12px;
        margin-left: 4px;
        opacity: 0.5;
      }
    }

    .cell-type {
      display: flex;
      align-items: center;

      svg {
        margin-right: 5px;
      }
    }
  }

  &-list {

    .list-item {
      padding: 15px 0;
      border-bottom: 1px solid rgba($border-default, 0.3);

      &:last-child {
        border-bottom: 0;
      }

      &-title {
        color: $text-gray;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 600;
        letter-spacing: 1px;
        line-height: 25px;
      }

      &-row:not(:last-child) {
        margin-bottom: 10px;
      }

      &-label {
        color: $text-black;
        font-size: 16px;
        line-height: 25px;
      }

      &-value {
        color: $text-gray;
        font-size: 14px;
        line-height: 17px;
      }

      &-column {
        color: rgba($list-gray, 0.5);
        margin-left: 19px;
        margin-bottom: 0;

        li {
          font-size: 18px;

          span {
            margin-left: -5px;
            font-size: 16px;
            line-height: 24px;
            color: $text-black;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 666px) {

    &-list {
      background-color: $white;
      padding: 0 20px;
      border-radius: 10px;
      border: 1px solid rgba(0,0,0,0.05);
      box-shadow: 0 2px 4px 0 rgba(0,0,0,0.08);

      .list-item {

        &-title {
          margin-bottom: 10px;
          color: $text-black;
          text-transform: none;
          font-size: 18px;
          line-height: 21px;
        }
      }
    }
  }
  .ant-upload-list-item-name {
    color: white !important;
  }
}
.fontAkiraExpanded {
  font-family: 'Akira Expanded', sans-serif;
}
.fontBreymontBold {
  font-family: 'Breymont', sans-serif;
  font-weight: bold;
}
.fontBreymontLight {
  font-family: 'Breymont', sans-serif;
  font-weight: lighter;
}
